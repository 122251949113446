import React, { useEffect, useRef } from 'react';

const Home = () => {
  const videoRef = useRef(null);
  const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const playPauseResetLoop = async () => {
        try {
          await videoElement.play(); // Play the video
          videoElement.onended = () => {
            setTimeout(() => {
              videoElement.currentTime = 0; // Reset video to the start
              videoElement.play(); // Play the video again after the pause
            }, 30000); // Wait 30 seconds before restarting the video
          };
        } catch (error) {
          console.error('Error playing video:', error);
        }
      };

      playPauseResetLoop();
    }
  }, []);

  return (
    <section
      id='home'
      style={{
        height: '100vh',
        width: '100%', // Changed from '100vw' to '100%'
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/homeGradientWide.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        margin: 0, // Ensure no margin
        padding: 0, // Ensure no padding
      }}
    >
      <video
        ref={videoRef}
        src={
          isMobile
            ? 'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Home/homeVideoMobile.mp4'
            : 'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Home/homeVideoR.mp4'
        }
        muted
        autoPlay
        loop
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', // Changed from '100vw' to '100%'
          height: '100%', // Changed from '100vh' to '100%'
          objectFit: 'cover',
          zIndex: 1,
        }}
      />

      {/* Text Overlay */}
      <div
        style={{
          position: 'absolute',
          top: '10%', // Adjust based on screen size
          left: '10%', // Adjust based on screen size
          color: '#FFFFFF',
          fontSize: '8vw', // Use viewport width units for responsive scaling
          fontFamily: "'Bangers', sans-serif",
          zIndex: 2,
        }}
      >
        Tennis
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '5%', // Adjust based on screen size
          right: '5%', // Adjust based on screen size
          color: '#FFFFFF',
          fontSize: '8vw', // Use viewport width units for responsive scaling
          fontFamily: "'Bangers', sans-serif",
          zIndex: 2,
        }}
      >
        Set Free
      </div>
    </section>
  );
};

export default Home;
