// src/CardComponent.js

import React, { useState, useEffect } from 'react';
import { motion, useTransform, useScroll } from 'framer-motion';
import './CardComponent.css';

const Card = ({ id, title, description, videoUrl, imageUrl1, color }) => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, 50]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    checkIsMobile();

    // Event listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  if (isMobile) {
    // Mobile Layout
    return (
      <motion.div
        id={id}
        className='card-container-mobile'
        style={{
          backgroundColor: '#141821',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {/* Title and Description Section */}
        <div className='card-content-mobile' style={{ marginBottom: '15px' }}>
          <h2 className='card-title-mobile'>{title}</h2>
          <p className='card-description-mobile'>{description}</p>
        </div>

        {/* Video Section */}
        <div style={{ flex: 1 }}>
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{
              width: '100%',
              borderRadius: '10px',
              objectFit: 'cover',
            }}
          >
            <source src={videoUrl} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      </motion.div>
    );
  } else {
    // Desktop Layout
    return (
      <motion.div
        id={id}
        className='card-container'
        style={{
          backgroundColor: '#141821',
          height: '75vh',
          display: 'flex',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {/* Left Side */}
        <div style={{ flex: 1, marginRight: '20px' }}>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              height: '100%',
              border: '0.5px solid rgba(255, 255, 255, 0.5)',
              borderRadius: '10px',
            }}
          >
            <div className='card-left' style={{ flex: 1, padding: 10 }}>
              <h2
                className='card-title'
                style={{ color: 'white', marginBottom: '10px' }}
              >
                {title}
              </h2>
              <p
                className='card-description'
                style={{ color: 'white', marginBottom: '20px' }}
              >
                {description}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={imageUrl1}
                alt=''
                style={{
                  height: '100%',
                  borderRadius: '10px',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div style={{ flex: 1 }}>
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              objectFit: 'cover',
            }}
          >
            <source src={videoUrl} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      </motion.div>
    );
  }
};

export default Card;
