// src/AppIntroSection.js

import React, { useState, useEffect } from 'react';
import './AppIntroSection.css';

const AppIntroSection = () => {
  const words = ['Practice', 'Learn', 'Win'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen is mobile-sized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider screens <= 768px as mobile
    };

    // Initial check
    handleResize();
    // Add event listener to check on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Change word every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section id='app-intro-section' className='app-intro-section'>
      <div className='outer-content'>
        <div className='intro-content'>
          <h2 className='company-title'>Welcome to RallyWon</h2>
          <div className='text-container'>
            <div className='a'>A</div>
            <div className='n'>New</div>
            <div className='w'>Way</div>
            <div className='t'>To</div>
            <div className='p'>{words[currentWordIndex]}</div>
          </div>
        </div>

        {/* Only render video if not on mobile */}
        {!isMobile && (
          <video
            src='https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Home/phoneLogo.mp4'
            muted
            autoPlay
            loop
            playsInline
            controls={false}
            className='intro-video'
          />
        )}
      </div>
    </section>
  );
};

export default AppIntroSection;
