// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css'; // Import the global CSS reset

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// Initial render
render();

// Hot Module Replacement (HMR) setup
if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
