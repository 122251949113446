// src/QRCodeSection.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './QRCodeSection.css';

const QRCodeSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen is mobile-sized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider screens <= 768px as mobile
    };

    // Initial check
    handleResize();
    // Add event listener to check on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section
      id='qr-code-section'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/appIntroGradient.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Left Side - Contact Information and Social Media Links */}
      <div className='qr-left'>
        {/* Show the link or QR code depending on mobile status */}
        {isMobile && (
          <a
            href='https://apps.apple.com/us/app/rallywon/id6479434178'
            className='download-link'
            style={{ marginTop: '1rem' }}
          >
            Download the App
          </a>
        )}
        <h3>Contact Us</h3>
        <p>Email: rallywoninc@gmail.com</p>

        <h3>Follow Us</h3>
        <p>
          <a href='https://facebook.com'>Facebook</a>
        </p>
        <p>
          <a href='https://twitter.com'>Twitter</a>
        </p>
        <p>
          <a href='https://instagram.com'>Instagram</a>
        </p>
        {/* Link to the Support Page */}
        <h3>Need Help?</h3>
        <Link to='/support' className='support-link'>
          Visit our <span style={{ color: '#FFD700' }}>Support</span> Page
        </Link>
      </div>

      {/* Right Side - Only display this div on non-mobile */}
      {!isMobile && (
        <div className='qr-right'>
          <img
            src={`${process.env.PUBLIC_URL}/images/qrCode.png`}
            alt='QR Code'
            className='qr-code-image'
          />
        </div>
      )}
    </section>
  );
};

export default QRCodeSection;
