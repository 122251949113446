// src/components/Support.js

import React from 'react';
import './Support.css';

const Support = () => {
  return (
    <section className='support-section'>
      <h2 style={{ marginTop: 30 }}>Support</h2>

      <div className='support-content'>
        <div className='support-info'>
          <h3>Customer Service Contact</h3>
          <p>
            Email:{' '}
            <a href='mailto:rallywoninc@gmail.com'>rallywoninc@gmail.com</a>
          </p>
        </div>

        <div className='support-services'>
          <h3>Our Services</h3>
          <ul>
            <li>Personalized Tennis Coaching</li>
            <li>Video Swing Analysis</li>
            <li>Live Lessons & Clinics</li>
            <li>Animated Video Instruction</li>
          </ul>
        </div>

        <div className='support-policies'>
          <h3>Policies</h3>
          <p>
            <strong>Refund and Dispute Policy:</strong> We offer refunds on a
            case-by-case basis for lessons and other services. Please contact us
            at <a href='mailto:rallywoninc@gmail.com'>rallywoninc@gmail.com</a>{' '}
            if you are unhappy with your purchase.
          </p>
          <p>
            <strong>Cancellation Policy:</strong> You can manage or cancel your
            subscriptions through the app or directly via the Apple App Store or
            Google Play Store. For lessons, please contact us at least 24 hours
            in advance to cancel or reschedule.
          </p>
          <p>
            <strong>Terms of Service:</strong>{' '}
            <a href='/terms-of-service'>View Terms of Service</a>
          </p>
        </div>

        <div className='support-delete-account'>
          <h3>Delete Account</h3>
          <p>
            If you wish to delete your account, please follow the instructions
            below:
          </p>
          <ol>
            <li>Click the 'Account' Bottom Tab.</li>
            <li>Click the 'Delete Account' button.</li>
          </ol>
          <p>
            If you encounter any issues, feel free to reach out to us at{' '}
            <a href='mailto:rallywoninc@gmail.com'>rallywoninc@gmail.com</a>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Support;
