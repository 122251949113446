// src/components/MembersMenu.js
import React from 'react';
import { Button, Heading, View } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

const MembersMenu = () => {
  const { signOut, user } = useAuthenticator((context) => [
    context.signOut,
    context.user,
  ]);
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut();
    navigate('/', { replace: true }); // Redirect to home after sign out
  };

  return (
    <View padding='2rem'>
      <Heading level={2}>Members Menu</Heading>
      <p>Welcome, {user.username}!</p>
      {/* Add your members-only content here */}
      <Button onClick={handleSignOut}>Sign Out</Button>
    </View>
  );
};

export default MembersMenu;
