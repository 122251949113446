import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Card from './CardComponent';
import { services } from '../data';
import AppIntroSection from './AppIntroSection';

gsap.registerPlugin(ScrollTrigger);

function ServicesSection() {
  const appIntroRef = useRef(null);

  useEffect(() => {
    // Your ScrollTrigger logic here
  }, []);

  return (
    <div style={{ backgroundColor: '#141821' }}>
      <div
        ref={appIntroRef}
        style={{
          height: '25vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: '#141821',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#FFFFFF',
          textAlign: 'center',
          zIndex: 1000,
          position: 'sticky',
          top: 0,
        }}
      >
        <AppIntroSection />
      </div>

      {services.map((service, index) => (
        <Card key={index} id={`card-${index}`} {...service} />
      ))}
    </div>
  );
}

export default ServicesSection;
