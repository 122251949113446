// src/components/Login.js
import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import MyAppHeader from './MyAppHeader';
import './Login.css'; // Import the CSS file for styling
//import './AuthenticatorOverrides.css'; // Import the CSS file for stylingx

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the intended path from location state or default to '/members'
  const from = location.state?.from?.pathname || '/members';

  return (
    <div className='login-container'>
      {' '}
      {/* Container for styling */}
      <Authenticator
        components={{ Header: MyAppHeader }}
        hideSignUp={false} // Set to true if you want to hide the sign-up option
        onSignIn={() => navigate(from, { replace: true })} // Redirect after sign-in
      >
        {/* The Authenticator handles the login UI */}
      </Authenticator>
    </div>
  );
};

export default Login;
