// src/data.js
export const services = [
  {
    title: 'Swing Analysis',
    description:
      'Unlock the power of data-driven insights with our SwingAnalysis tool. Upload a video of your swing, and our advanced technology tracks key body movements, generating detailed metrics and data graphs. Compare your performance against a professional model built from top-tier player swings, and elevate your game with precise, actionable feedback.',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Demos/SwingAnalysisDemo.mp4',
    imageUrl1: `${process.env.PUBLIC_URL}/images/swingAnalysis.png`,
    color: '#141821',
  },
  {
    title: 'Aggregate Analysis',
    description:
      'Aggregate Analysis offers a comprehensive comparison of your swing metrics against those of professional players. By tracking your average angles and other key stats through contact, this feature highlights how your performance stacks up, providing percentile rankings and a visual graph to illustrate your progress. Understand exactly where you stand and pinpoint areas for improvement with this data-driven approach.',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Demos/aggAnalysisFull.mp4',
    imageUrl1: `${process.env.PUBLIC_URL}/images/aggAnalysisStill.png`,
    color: '#141821',
  },
  {
    title: 'Digital Lessons',
    description:
      'Traditional lessons are 5% insight and 95% feeding. Dont pay $75/hr to be fed balls. At RallyWon, we bring modern tech and hard data to bear with personalized animation models, custom lesson plans and stats driven by machine learning. Pay for the insight and practice for free.',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/Demos/momLesson.mp4',
    imageUrl1: `${process.env.PUBLIC_URL}/images/lessonRequest.png`,
    color: '#141821',
  },
];
