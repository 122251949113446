// src/components/MyAppHeader.js
import React from 'react';
import { Flex, Heading } from '@aws-amplify/ui-react';
import logo from '../assets/images/logo1NB.png'; // Ensure this path is correct

const MyAppHeader = () => {
  return (
    <Flex
      direction='row'
      justifyContent='center'
      alignItems='center'
      gap='10px'
      marginBottom='20px'
    >
      <Heading level={1} color='white' fontSize='2em'>
        RallyWon
      </Heading>
      <img
        src={logo}
        alt='RallyWon Logo'
        style={{ width: '50px', height: '50px' }}
      />
    </Flex>
  );
};

export default MyAppHeader;
