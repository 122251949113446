// src/VideoLibrary.js

import React, { useState, useEffect, useRef } from 'react';
import './VideoLibrary.css';

const videos = [
  {
    title: 'Forehand Overview',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/instructional/ForehandOverview.mp4',
    thumbnailUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/images/instructional/thumbnails/ForehandOverview.png',
  },
  {
    title: 'Topspin Basics',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/instructional/TopspinBasicsF.mp4',
    thumbnailUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/images/instructional/thumbnails/TopspinBasics.png',
  },
  {
    title: 'Topspin Vs Rally',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/instructional/TopspinVsRally.mp4',
    thumbnailUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/images/instructional/thumbnails/TopspinVsRally.png',
  },
  {
    title: 'Volley Overview',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/instructional/VolleyOverview.mp4',
    thumbnailUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/images/instructional/thumbnails/VolleyOverview.png',
  },
  {
    title: 'Volley Paths',
    videoUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/videos/instructional/VolleyPaths.mp4',
    thumbnailUrl:
      'https://rally1media110131-dev.s3.amazonaws.com/web-app/images/instructional/thumbnails/VolleyPaths.png',
  },
];

const VideoLibrary = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const [isPaused, setIsPaused] = useState(true);
  const videoRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen is mobile-sized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider screens <= 768px as mobile
    };

    // Initial check
    handleResize();

    // Add event listener to check on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Event handler for play/pause
  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  };

  // Add event listeners for play and pause events
  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('play', handlePlayPause);
    videoElement.addEventListener('pause', handlePlayPause);

    return () => {
      videoElement.removeEventListener('play', handlePlayPause);
      videoElement.removeEventListener('pause', handlePlayPause);
    };
  }, []);

  // Desktop Layout
  if (!isMobile) {
    return (
      <div id='VideoLibrary' style={{ height: '300vh', position: 'relative' }}>
        {/* Fullscreen Video */}
        <div
          id='video-container'
          style={{
            position: 'sticky',
            top: '0',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#141821',
          }}
        >
          <video
            ref={videoRef}
            key={currentVideo.videoUrl}
            controls
            style={{ width: '75%', maxHeight: '80vh' }}
          >
            <source src={currentVideo.videoUrl} type='video/mp4' />
            Your browser does not support the video tag.
          </video>

          {/* Title at the top of the video */}
          <div className='video-library-title-container'>
            <h1 className='video-library-title'>Video Library</h1>
          </div>

          {/* Subtitle at the bottom of the video */}
          <div className='video-library-subtitle-container'>
            <p className='video-library-subtitle'>
              videos of instruction, mechanics, drills and more
            </p>
          </div>
        </div>

        {/* Thumbnails that slide in/out */}
        <div
          id='thumbnails-container'
          style={{
            position: 'absolute',
            top: '100vh',
            right: '0px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#141821',
            boxShadow: '0 0 10px rgba(0,0,0,0.5)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {videos.map((video, index) => (
              <div
                key={index}
                onClick={() => setCurrentVideo(video)}
                style={{
                  cursor: 'pointer',
                  border:
                    video.videoUrl === currentVideo.videoUrl
                      ? '3px solid #00ff00'
                      : '3px solid transparent',
                  borderRadius: '5px',
                }}
              >
                <img
                  src={video.thumbnailUrl}
                  alt={video.title}
                  style={{
                    width: '150px',
                    height: '175px',
                    borderRadius: '5px',
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Mobile Layout
  return (
    <div id='VideoLibraryMobile' style={{ position: 'relative' }}>
      {/* Title */}
      <div className='video-library-title-container-mobile'>
        <h1 className='video-library-title-mobile'>Video Library</h1>
        <p className='video-library-subtitle-mobile'>
          videos of instruction, mechanics, drills and more
        </p>
      </div>

      {/* Video */}
      <div style={{ backgroundColor: '#141821' }}>
        <video
          ref={videoRef}
          key={currentVideo.videoUrl}
          controls
          style={{ width: '100%', maxHeight: '60vh' }}
        >
          <source src={currentVideo.videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Thumbnails */}
      {isPaused && (
        <div
          style={{
            padding: '10px',
            display: 'flex',
            overflowX: 'scroll',
            backgroundColor: '#141821',
          }}
        >
          {videos.map((video, index) => (
            <div
              key={index}
              onClick={() => {
                setCurrentVideo(video);
                if (!videoRef.current.paused) {
                  videoRef.current.pause();
                }
              }}
              style={{
                cursor: 'pointer',
                border:
                  video.videoUrl === currentVideo.videoUrl
                    ? '3px solid #00ff00'
                    : '3px solid transparent',
                borderRadius: '5px',
                marginRight: '10px',
                flex: '0 0 auto',
              }}
            >
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                style={{ width: '100px', height: '115px', borderRadius: '5px' }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VideoLibrary;
