import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './Header.css';

const Header = () => {
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const iconHeight = '40px';

  // Access user authentication status
  const { user } = useAuthenticator((context) => [context.user]);

  // Determine the account link path based on authentication
  const accountPath = user ? '/members' : '/login';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className={`header ${isScrollingUp ? 'visible' : 'hidden'}`}>
      <div className='logo'>
        {/* Use RouterLink instead of ScrollLink to navigate to the home page */}
        <RouterLink to='/' onClick={scrollToTop}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ height: iconHeight }}
          />
        </RouterLink>
      </div>
      <nav className='nav-icons'>
        {/* Account Icon */}
        {/* <RouterLink to={accountPath}>
          <img
            src={`${process.env.PUBLIC_URL}/images/accountIcon.png`}
            alt='Account'
            style={{ height: iconHeight }}
          />
        </RouterLink> */}
      </nav>
    </header>
  );
};

export default Header;
